import Vue from 'vue';
import Vuex from 'vuex';
import facilities from '@stores/modules/facilities';
import map from '@stores/modules/map';
// eslint-disable-next-line import/no-cycle
import search from '@stores/modules/search';
import track from '@stores/modules/track';
import payments from '@stores/modules/payments';
import notifications from '@stores/modules/notifications';
import tours from '@stores/modules/tours';
import account from '@stores/modules/account';
import matchesRequest from '@stores/modules/matches-request';
import legup from '@stores/modules/legup';
import { toCamelCase } from '@utils';
import { fetchLatestTour } from '@stores/modules/tours.service';
import hubspot from '@services/hubspot.service';
import userService from '@services/user.service';
import facility from '@stores/modules/facility';
import messaging from '@stores/modules/messaging';
import recurringPayments from '@stores/modules/recurringPayments';
import dependents from '@stores/modules/dependents';
import surveys from './modules/surveys';

import {
  fetchFacility,
  fetchFacilityTourMode,
  fetchFacilityTourSlots,
  reImpersonateParent,
  requestMoreMatches,
  sendWaitlistSpotRequest,
  getWaitlistSpots,
  getWaitlistSpotsWithCenters,
} from './parent.service';
import {
  fetchMatchesFacilitiesForParent,
} from '../../common/stores/modules/facilities.service';
import user from './modules/parent-user';

Vue.use(Vuex);

const MATCHES_PHASE_MAP = {
  0: 'initialEmpty',
  1: 'searchInProgress',
  2: 'postReview',
  3: 'unavailable',
};

const loadFacilityData = async ({ dispatch, commit, getters }, friendlyId, fetchFunction) => {
  const fetchedFacility = await fetchFunction(friendlyId);
  const tour = await fetchLatestTour(fetchedFacility.id);
  if (fetchedFacility.opinion) {
    commit('facilities/setOpinionState', { facilityId: fetchedFacility.id, opinion: fetchedFacility.opinion });
  }
  dispatch('track/event', {
    category: 'marketplace',
    event: getters.isPreviewMode
      ? 'view_caregiver_profile_as_preview'
      : 'view_caregiver_profile',
    props: {
      caregiver_profile_id: fetchedFacility.id,
      caregiver_profile_name: fetchedFacility.name,
      caregiver_profile_network_label: fetchedFacility.networkLabel,
      caregiver_profile_partner_name: fetchedFacility.partnerName,
      caregiver_profile_philosophy: fetchedFacility.philosophy,
      caregiver_profile_url_fragment: fetchedFacility.urlEnding,
      legup_center_id: fetchedFacility.legupCenterId,
    },
  });

  return { fetchedFacility, tour };
};

export default new Vuex.Store({
  modules: {
    facilities,
    facility,
    map,
    search,
    track,
    user,
    payments,
    notifications,
    tours,
    account,
    matchesRequest,
    legup,
    messaging,
    surveys,
    recurringPayments,
    dependents,
  },

  state: {
    // on enter
    isMobile: false,

    autoFocusRequestForm: false,

    isInitialized: false,

    showImpersonationError: false,

    matchesPhase: null,

    hasSeenMatchesNote: localStorage.getItem('hasSeenMatchesNote'),

    waitlistSpotCenters: [],
  },

  getters: {
    showShortlist(s, g, rootState) {
      return rootState.route.name === 'shortlist';
    },
    showFavorited(s, g, rootState) {
      return rootState.route.name === 'favorited';
    },
    showIgnored(s, g, rootState) {
      return rootState.route.name === 'ignored';
    },
    showRecords(s, g, rootState) {
      return rootState.route.name === 'search';
    },
    showMatches(s, g, rootState) {
      return rootState.route.name === 'matches';
    },
    showSaved(s, g, rootState) {
      return rootState.route.name === 'saved';
    },
    showFacility(s, g, rootState) {
      return rootState.route.name === 'facility';
    },
    showSearchForm(s, g, rootState) {
      return rootState.route.path === '/childcare/search-form';
    },
    impersonationErrorStatus(state) {
      return state.showImpersonationError;
    },
    isUserStillImpersonated: (state) => async () => {
      const beUser = await userService.getUser();
      return beUser.id === state.user.current.id;
    },
    matchesPhase: (state) => {
      // if matches phase is the initial empty state, and the match request
      // is complete, show the search in progress state.
      if (state.matchesPhase === 0 && state.matchesRequest.requestStatus === 'complete') {
        return MATCHES_PHASE_MAP[1];
      }
      return MATCHES_PHASE_MAP[state.matchesPhase];
    },
    isPreviewMode: (state) => {
      // if url has preview param or is a provider user
      const { isProvider, previewProfile } = state.user.current;
      const params = Object.fromEntries(new URLSearchParams(window.location.search));
      return isProvider || previewProfile || !!params.preview;
    },
    waitlistSpotCenters: (state) => state.waitlistSpotCenters,
  },

  mutations: {
    setInitialized: (state) => {
      state.isInitialized = true;
    },

    chatHasBeenAutoOpened: (state, payload) => {
      state.chatHasBeenAutoOpened = payload;
    },

    setAutoFocusRequestForm: (state, payload) => {
      state.autoFocusRequestForm = payload;
    },

    setImpersonationErrorStatus: (state, payload) => {
      state.showImpersonationError = payload;
    },
    setMatchesPhase(state, phase) {
      state.matchesPhase = phase;
    },
    setHasSeenMatchesNote(state, value) {
      state.hasSeenMatchesNote = value;
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setWaitlistSpotCenters(state, value) {
      state.waitlistSpotCenters = value;
    },
  },

  actions: {
    openChat: () => hubspot.open(),

    async loadFacility({ dispatch, commit, getters }, friendlyId) {
      return loadFacilityData(
        { dispatch, commit, getters },
        friendlyId,
        fetchFacility,
      );
    },

    async fetchFacilityTourMode({ dispatch }, facilityId) {
      try {
        const tourMode = await fetchFacilityTourMode(facilityId);
        return tourMode;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load the tour mode', error }, { root: true });
        return undefined;
      }
    },

    async fetchFacilitySlots({ dispatch }, { currentPage, facilityId }) {
      try {
        const page = currentPage + 1;
        const { tourSlots, noTours } = await fetchFacilityTourSlots(facilityId, page);
        return { tourSlots, noTours, page };
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load facility slots', error }, { root: true });
        return { currentPage, tourSlots: {} };
      }
    },

    setState: ({ commit }, newState) => {
      const camelCasedState = toCamelCase(newState);
      Object.keys(camelCasedState).forEach((key) => {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        const value = camelCasedState[key];
        commit(`set${capitalizedKey}`, value);
      });
    },

    async init({
      dispatch, commit, getters, state,
    }) {
      if (!getters.showRecords && !getters.isPreviewMode) {
        dispatch('search/initSearch', true);
      }

      await dispatch('user/fetchCurrent');

      if (!getters.isPreviewMode) {
        await Promise.all([
          dispatch('dependents/fetchDependents', { parentId: state.user.current.id }),
          dispatch('user/fetchEnrollments'),
        ]);
      }

      return commit('setInitialized');
    },

    isInitialized({ state }) {
      return new Promise((resolve) => {
        const checkInitializedState = () => {
          setTimeout(() => {
            if (state.isInitialized) {
              resolve();
            } else {
              checkInitializedState();
            }
          }, 300);
        };
        if (state.isInitialized) {
          resolve();
        } else {
          checkInitializedState();
        }
      });
    },

    hideImpersonationError({ commit }) {
      commit('setImpersonationErrorStatus', false);
    },

    async hasImpersonationError({ state, getters, commit }) {
      const currentUser = state.user.current;
      if (currentUser.isImpersonated
        && !(await getters.isUserStillImpersonated())) {
        commit('setImpersonationErrorStatus', true);
        return true;
      }

      return false;
    },

    async reImpersonateParent({ state, dispatch }) {
      const success = await reImpersonateParent(state.user.current.id);
      if (success) dispatch('hideImpersonationError');
    },

    async requestMoreMatches({ commit }) {
      await requestMoreMatches();
      const matchesData = await fetchMatchesFacilitiesForParent();
      await commit('setMatchesPhase', matchesData.empty_state);
    },

    // eslint-disable-next-line no-unused-vars
    async sendWaitlistSpotRequest({ dispatch }, params) {
      await sendWaitlistSpotRequest(params);
    },

    async getWaitlistSpots({ dispatch }) {
      try {
        const spots = await getWaitlistSpots();
        return spots;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the waitlist spots', error }, { root: true });
        return undefined;
      }
    },

    async getWaitlistSpotsWithCenters({ dispatch }) {
      try {
        const spots = await getWaitlistSpotsWithCenters();
        return spots;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not retrieve the waitlist spots', error }, { root: true });
        return undefined;
      }
    },
  },
});
